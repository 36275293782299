<template>
  <div class="w-full flex h-screen overflow-hidden">
    <div>      
      <MenuSidebarComponent />
      <MenuMobileComponent />
    </div>
    <div class="overflow-hidden h-full w-full">
      <HeaderComponent />
      <router-view :key="$route.path" />
      <div v-if="$route.path === '/'" class="h-full w-full flex flex-col mt-[80px] ml-[80px]">
        <p class="font-bold text-[40px]">{{ $t("header.welcome", { name: username }) }}</p>
        <p class="text-[22px]">{{ $t("header.date", { date: getFormattedDate }) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import MenuSidebarComponent from "@/components/globalWeb/menuSidebarComponent.vue";
import MenuMobileComponent from "@/components/globalWeb/menuMobileComponent.vue";
import HeaderComponent from "@/components/globalWeb/headerComponent.vue";
import { useLoginStore } from "@/store/loginStore";
import { useHomeConfig } from "@/store/homeConfigStore.js";
import { useMenuSidebarStore } from "@/store/menuStore";


export default {
  name: "HomePage",
  data() {
    const loginStore = useLoginStore();
    const homeConfig = useHomeConfig();
    const menuStore = useMenuSidebarStore();
    return {
      dynamicWidth: window.innerWidth,
      loginStore,
      homeConfig,
      menuStore,
    };
  },
  components: {
    MenuSidebarComponent,
    MenuMobileComponent,
    HeaderComponent,
  },
  methods: {
    atualizarLarguraPagina() {
      this.dynamicWidth = window.innerWidth;
    },
  },
  computed: {
    isSideBarOpen() {
      return (this.dynamicWidth <= 768 )
    },
    username() {
      const storedUsername = localStorage.getItem("@vueweb:loggedUser");
      return storedUsername !== null && storedUsername !== "undefined"
        ? storedUsername
        : "Eztools";
    },
    getFormattedDate() {
      const today = new Date();
      const options = { weekday: "long", day: "numeric", month: "long", year: "numeric" };
      const locale = this.$i18next.language || "pt-BR";
      const formattedDate = today.toLocaleDateString(locale, options);
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    },
  },
  async mounted() {
    this.loginStore.profileImage =
      localStorage.getItem("@vueweb:imageProfile") !== null &&
      localStorage.getItem("@vueweb:imageProfile") !== "undefined"
        ? localStorage.getItem("@vueweb:imageProfile")
        : null;
    window.addEventListener("resize", this.atualizarLarguraPagina);
    if (this.menuStore.itemsGeneralMenu.length === 0) {
      await this.menuStore.getMenu();
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.atualizarLarguraPagina);
  },
  async created() {
    await this.homeConfig.getUserHome();
  }
};
</script>