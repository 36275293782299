import { apiDev } from "../../services/api";
import { defineStore } from "pinia";
import { apiBackExample } from "@/api-examples/dashBackExample";
import { useStorage } from "@vueuse/core";
import { useApi } from "../api-gridStore";

import formApi from "@/api-examples/formApi";
import dashApiExemple from "@/api-examples/dashApiExemple";
import apiTable from "@/api-examples/api-table";

export const useDash = defineStore("dash", {

  state: () => ({

    data: [],
    dataForm: {},
    options: [],
    currentId: null,
    headersHeight: {},
    elements: [],
    savedDashFilters: useStorage("v2@eztools.filters.dash", {}),
    savedElementFilters: useStorage("@eztools.filter.element.saved", {}),
    requiredDashElements: {},
  }),


  actions: {
    setJsonData(data) {
      this.data["data"] = data;
    },

    async getSelectElementOptions(currentElement) {

      let currentSelectElement = this.elements[this.currentId].find((element) => element.id === currentElement.id)
      currentSelectElement.loading = true;

      let query = `${currentElement.id}`

      if (currentElement.parent) {
        const parentItem = this.elements[this.currentId].find((element) => element.id === currentElement.parent);
        query += `?filter=${parentItem.model ? parentItem.model.id : ''}`;
      }

      await apiDev
        .get(`/handler/v2/dash/load/header/${query}`)
        .then((response) => {
          const optionsData = response.data.data
          currentSelectElement.options = optionsData;

          if (
            currentSelectElement.model &&
            !optionsData.some((option) => option.id === currentSelectElement.model.id)
          ) {
            currentSelectElement.model = null;
            delete this.savedDashFilters[this.currentId][currentSelectElement.id];
            delete this.savedElementFilters[currentSelectElement.id];
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          currentSelectElement.loading = false;
        })
    },

    async updateElementsModel(updatedElementId, newModel) {
      const apiGrid = useApi();

      const currentDashElements = this.elements[this.currentId];
      const currentElement = currentDashElements.find((element) => element.id === updatedElementId);
      const currentElementChildren = currentDashElements.filter(
        (element) => element.parent === updatedElementId
      );

      currentElement.model = newModel;

      if (newModel === null) {
        delete this.savedElementFilters[updatedElementId];
      } else {
        this.savedElementFilters[updatedElementId] = newModel;
      }

      if (!this.savedDashFilters[this.currentId]) {
        this.savedDashFilters[this.currentId] = {};
      }

      if (newModel === null) {

        delete this.savedDashFilters[this.currentId][updatedElementId];

        currentElementChildren.forEach((child) => {
          child.model = null;
          delete this.savedDashFilters[this.currentId][child.id];
          delete this.savedElementFilters[child.id];
        });

      } else {
        if (Array.isArray(newModel)) {
          this.savedDashFilters[this.currentId][updatedElementId] = newModel.map((model) => model.id);
        } else if (currentElement.type === 'ITXT') {
          this.savedDashFilters[this.currentId][updatedElementId] = newModel;
        } else {
          this.savedDashFilters[this.currentId][updatedElementId] = newModel.id;
        }
      }

      if (this.requiredDashElements[this.currentId] && this.requiredDashElements[this.currentId].some(el => el.id === updatedElementId)) {
        apiGrid.showNotify();
      }

      await currentElementChildren.forEach((child) => {
        this.getSelectElementOptions(child);
      });
    },

    //eslint-disable-next-line
    async getData(id) {
      const dataId = id;
      this.currentId = id;
      if (dataId.includes("teste")) {
        this.data = dashApiExemple.find(
          (dash) => dash.id == dataId.split("teste")[0]
        );
        return;
      }
      if (dataId === "23a") {
        this.data = dashApiExemple.find((api) => api.id == 24);

        return;
      } else if (dataId === "999") {
        this.data = apiBackExample.find((element) => element.id === 24);
        return;
      }

      const res = await apiDev
        .get(`/handler/v2/dash/load/${dataId}`)
        .then((res) => res.data)
        .catch((err) => err);
      this.data = res.success ? res.data : res;
    },

    async getForm(id) {
      //// console.log(id);
      const res = formApi.find((api) => api.id == id);
      this.dataForm = res.data;
    },

    async getOptions(id) {
      const data = (await apiTable).find((opt) => opt.id === id);
      this.options = data.options;
    },
  },
});
